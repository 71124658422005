<template>
  <div>
    <div class="bg-gray-200 m-auto h-screen text-center content-center">
      <span>Запрашиваемой страницы нет на сайте!</span>
    </div>
  </div>
</template>

<script>
export default {
  components: {

  }
}
</script>

<style>
</style>
